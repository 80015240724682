import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { kebabCase } from 'lodash'

import SEO from './seo'
import Layout from './layout'
import Header from './header'
import Box from './Box'
import Container from './Container'
import Heading from './Heading'
import Text from './Text'
import Button from './Button'
import Flex from './Flex'
import FlexItem from './FlexItem'
import ApplyPopUp from './ApplyPopUp'
import styles from './postLayout.module.css'
import Share from './Share'

export default class postLayout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasScrolled: false
        }

        this.state = {
            isDesktop: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll',
        this.handleScroll)
    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset
        
        if (scrollTop > 20) {
            this.setState({ hasScrolled: true })  
        } 
        // else if ( window.innerWidth > 1040 ) {
        //     this.setState({ isDesktop: true })
        // }
        else {
            this.setState({ hasScrolled: false })
        }
    }

    render () {
        const { markdownRemark } = this.props.data;

        return (
            <Layout>
                <div id="main">
                <SEO title={`${markdownRemark.frontmatter.title}`} />
                <Header />
                <Box className={this.state.hasScrolled ? styles.StickyNavScrolled : styles.StickyNav}>
                    <Box m="0 auto" maxWidth="1200px" p="0 24px">
                        <Flex justifyContent="space-between" alignItems="center">
                            <FlexItem>
                                <Flex>
                                    <Box width="88px">
                                        <Link
                                            to="/"
                                            style={{
                                            textDecoration: 'none',
                                            display: 'block',
                                            }}
                                        >
                                            <img src={`${markdownRemark.frontmatter.image}`} alt={markdownRemark.frontmatter.company}/> 
                                        </Link>
                                    </Box>
                                    <FlexItem alignSelf="center" ml="4">
                                        <Heading as="h5" fontSize={['3', '4']}>{markdownRemark.frontmatter.title}</Heading>
                                        <Text color="grey" fontSize="3">{markdownRemark.frontmatter.company}, {markdownRemark.frontmatter.location}</Text>
                                    </FlexItem>
                                </Flex>
                            </FlexItem>
            
                            <FlexItem>
                                {markdownRemark.frontmatter.link ? (
                                <Button as="a" variant="primary" href={`${markdownRemark.frontmatter.link}`}>
                                    Find out more
                                </Button>
                                ) : 
                                <Button as="a" variant="primary" href="#apply">
                                    Apply Now
                                </Button>
                                }
                            </FlexItem>
                        </Flex>
                    </Box>
                </Box>
                <Box as="article">
                    <Box my={['2', '2', '3']}>
                        <Container>
                            <Flex as="header" flexDirection={['column', 'column', 'row']} flex="3 1">
                                <FlexItem alignSelf="center" width={['100%', '100%', '75%']} pr={['0', '6']}>
                                    <Heading as="h1" fontSize={[5, 6, 6, 7]} mb={['2', '3']}>{markdownRemark.frontmatter.title}</Heading>
                                    <Text color="grey" fontSize={[3, 4]}>
                                        <Link to={`/jobs-at-${kebabCase(markdownRemark.frontmatter.company)}/`}>
                                            {markdownRemark.frontmatter.company}
                                        </Link>
                                    </Text>
                                </FlexItem>
                                <FlexItem as="figure" width={['100%', '100%', '25%']}>
                                    <img src={`${markdownRemark.frontmatter.image}`} alt={markdownRemark.frontmatter.company}/> 
                                </FlexItem>
                            </Flex>
                            <Flex flexDirection={['column', 'column', 'row']} justifyContent="space-between" mt={['0', '5']} mb={['0', '4']}>
                                {markdownRemark.frontmatter.location ? (
                                <FlexItem my={['2', '2', '0']}> 
                                    <Text fontSize="2" as="label" color='grey'>Location</Text>
                                    <Text fontSize={['3', '4']}>{markdownRemark.frontmatter.location}</Text>
                                </FlexItem>
                                ) : null }
                                {markdownRemark.frontmatter.tags ? (
                                <FlexItem my={['2', '2', '0']}>
                                    <Text fontSize="2" as="label" color='grey'>Industry</Text>
                                    {markdownRemark.frontmatter.tags.map(tag => (
                                        <Text fontSize={['3', '4']}>{tag}</Text>
                                    ))}
                                </FlexItem>
                                ) : null }
                                {markdownRemark.frontmatter.experience_level ? (
                                <FlexItem my={['2', '2', '0']}>
                                    <Text fontSize="2" as="label" color='grey'>Experience level</Text>
                                    <Text fontSize={['3', '4']}>{markdownRemark.frontmatter.experience_level}</Text>
                                </FlexItem>
                                ) : null }
                                {markdownRemark.frontmatter.position_type ? (
                                <FlexItem my={['2', '2', '0']}> 
                                    <Text fontSize="2" as="label" color='grey'>Job type</Text>
                                    <Text fontSize={['3', '4']}>{markdownRemark.frontmatter.position_type}</Text>
                                </FlexItem>
                                ) : null }
                                {markdownRemark.frontmatter.deadline ? (
                                <FlexItem my={['2', '2', '0']}>
                                    <Text fontSize="2" as="label" color='grey'>Deadline</Text>
                                    <Text fontSize={['3', '4']}>{markdownRemark.frontmatter.deadline}</Text>
                                </FlexItem>
                                ) : null }
                            </Flex>
                        </Container>
                    </Box>
                    <Box border="2px solid #ebebeb">
                    <Container>
                        <Flex flexDirection={['column', 'column', 'row-reverse']} mt={['0', '48px']} height="100%">
                            <FlexItem as="aside" width={['100%', '100%', '30%']} className={styles.StickyHighlights}>
                                
                                <Box mb={[4, 4, 0]} display={['none', 'block', 'block']} border="solid 2px #ebebeb" p="4">
                                    <Heading as="h3" fontSize={[4, 4]}>Company highlights</Heading>
                                    <Box mt="3" fontSize="2">
                                    {markdownRemark.frontmatter.highlights ? (
                                            <ul>
                                            {markdownRemark.frontmatter.highlights.map(highlight => (
                                            <li key={highlight}>{highlight}</li>
                                            ))}
                                        </ul>
                                    ) : null}
                                    </Box>

                                   
                                </Box>
                            
                            </FlexItem>
                            
                            <FlexItem as="section" width={['100%', '100%', '70%']} pr={['0', '6']}>
                                <Heading as="h3" mb="3">Company Mission</Heading>
                                <Text as="summary" mb="4" fontSize={['2', '3']} lineHeight="1.6">{markdownRemark.frontmatter.overview}</Text>
                                {markdownRemark.frontmatter.job_description ? (
                                    <>
                                    <Heading as="h3" mb="3">Job Description</Heading>
                                    <Text fontSize="2" className={styles.Content} dangerouslySetInnerHTML={{
                                        __html: markdownRemark.frontmatter.job_description
                                    }} />
                                    </>
                                ) : (
                                    <Box className={styles.Content} dangerouslySetInnerHTML={{
                                        __html: markdownRemark.html
                                    }} />
                                )} 

                                {markdownRemark.frontmatter.requirements ? (
                                    <Box className="requirements-container" mt="4">
                                    <Heading as="h3" mb="3">Job Requirements</Heading>
                                    <ul>
                                        {markdownRemark.frontmatter.requirements.map(item => (
                                        <li key={item}>
                                        {item}
                                        </li>
                                        ))}
                                    </ul>
                                    </Box>
                                ) : null }

                                {markdownRemark.frontmatter.link ? (
                                    <Box as="footer" mt="3">
                                        <Button as="a" variant="primary" href={`${markdownRemark.frontmatter.link}`}>
                                            Find out more
                                        </Button>
                                    </Box>
                                ) : 
                                    <Box as="footer" mt="3">
                                        <Button as="a" variant="primary" href="#apply">
                                            Apply Now
                                        </Button>
                                    </Box>
                                }
                            </FlexItem>
                        </Flex>
                        <Flex flexDirection={['column', 'column', 'row-reverse']} mt="24px" height="100%">
                            <FlexItem width={['100%', '100%', '30%']}>
                                <Share shareText="Check this site" url={`https://jobs.angelinvestmentnetwork.co.uk/${markdownRemark.frontmatter.slug}-at-${kebabCase(markdownRemark.frontmatter.company)}/`} />
                            </FlexItem>
                            {/* <FlexItem width={['100%', '100%', '70%']} pr={['0', '6']}>
                                <CallOut title="Want to post a job like this?" />
                            </FlexItem> */}
                        </Flex>
                    </Container>
                    </Box>
                </Box>
                
                </div>
                {/* Pass company and pageTitle props to child component  */}
                <ApplyPopUp company={markdownRemark.frontmatter.company} pageTitle={`${markdownRemark.frontmatter.title}`} />
            </Layout>
        )
    }
}

export const query = graphql`
    query postQuery($slug: String!) {
        markdownRemark(frontmatter: {
            slug: {
            eq: $slug
            }
        }) {
            html
            frontmatter {
            title
            date
            company
            location
            tags
            image
            slug
            link
            overview
            highlights
            job_description
            requirements
            experience_level
            position_type
            deadline(formatString: "DD MMM, YYYY")
            number_of_employees
            remote
            company_website
            company_twitter
            company_linkedin
            founder_linkedin
            }
        }
    }
`