import React from "react";
import styled from 'styled-components'
import Box from './Box'
import Text from './Text'
import InputText from './InputText'
import TextArea from './TextArea'
import InputFile from './InputFile'
import FormLabel from './FormLabel'
import Button from './Button'
import Heading from './Heading'

const Overlay = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 100ms;
  visibility: hidden;
  opacity: 0;
  background: rgba(255,255,255,0.5);
  min-height: 2000px;

  :target {
    visibility: visible;
    opacity: 1;
  }
`

const PopUpInner = styled.aside`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px;
  margin: 48px auto;
  max-width: 600px;
  padding: 24px;
  position: relative;
`

const Cancel = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
`

const Close = styled.a`
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 32px;
  text-decoration: none;
  color: #222;
`


function ApplyPopUp ( props ) {
  return (
    <Overlay id="apply">
      <Cancel href="#"></Cancel>
      <PopUpInner>
        <Close href="#">&times;</Close>
        <Heading as="h2" fontSize="5">Application form</Heading>
        <form 
          name="Apply Now" 
          method="POST" 
          data-netlify="true" 
          action="/success" 
          data-netlify-honeypot="bot-field"
          encType="multipart/form-data"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <InputText type="hidden" name="form-name" value="Apply Now" />
          <Box mt="4" mb="3">
            {/* Pass the company and pageTitle props to identify the job applicant is applying for */}
            <p hidden>
              <InputText name="company-name" value={props.company} type="text" readOnly/>
              <InputText name="job-description" value={props.pageTitle} type="text" readOnly/>
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
            </p>
            <FormLabel htmlFor="first-name">First Name*</FormLabel>
            <InputText id="first-name" name="first-name" placeholder="e.g. John" type="text" required/>
          </Box>
          <Box mb="3">
              <FormLabel htmlFor="last-name">Last Name*</FormLabel>
              <InputText id="last-name" name="last-name" placeholder="e.g. Smith" type="text" required/>
          </Box>
          <Box mb="3">
              <FormLabel htmlFor="contact-email">Contact Email*</FormLabel>
              <InputText id="contact-email" name="email" placeholder="e.g. john@smith.com" type="email" required/>
          </Box>   
          <Box mb="3">
              <FormLabel htmlFor="linkedin-url">LinkedIn URL</FormLabel>
              <InputText id="linkedin-url" name="linkedin-url" placeholder="e.g. https://linkedin.com/in/johnsmith/" type="text"/>
          </Box>
          <Box mb="3">
              <FormLabel htmlFor="upload-cv">Upload CV*</FormLabel>
              <InputFile id="upload-cv" name="upload-cv" type="file" accept="image/jpeg,image/png,application/pdf,image/x-eps" required/>
              <Text>Please upload a .pdf file!</Text>
          </Box>
          <Box mb="3">
              <FormLabel htmlFor="message">Message</FormLabel>
              <TextArea id="message" name="message" placeholder="Your message..." type="text"/>
          </Box>
          <Box mb="3">
              <FormLabel htmlFor="upload-letter">Upload Cover Letter (optional)</FormLabel>
              <InputFile id="upload-letter" name="upload-letter" type="file" accept="image/jpeg,image/png,application/pdf,image/x-eps"/>
              <Text>Please upload a .pdf file!</Text>
          </Box>
          <Box mb="3">
              <Button variant="primary" id="submit-button" width="100%" type="submit">Send</Button>
          </Box>
        </form>
      </PopUpInner>
    </Overlay>
  )
}


export default ApplyPopUp