import React from "react";
import { 
  FacebookShareButton, 
  LinkedinShareButton, 
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton, 

  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share'
import styles from './share.module.css'
import Box from './Box'
import Flex from './Flex'
import Text from './Text'

const Share = ( props ) => {

  return (
    <Box m="32px 0">
      <Text fontWeight="600" mt="0" mb="16px">Share on:</Text>
      <Flex alignItems="center" mb="8px">
        <FacebookShareButton
          url={props.url}
          quote={props.shareText}
          className={styles.button}>
          <FacebookIcon size={32} />
        </FacebookShareButton>
        <TwitterShareButton
          url={props.url}
          title={props.shareText}
          className={styles.button}>
          <TwitterIcon size={32} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={props.url}
          title={props.shareText}
          separator=":: "
          className={styles.button}>
          <WhatsappIcon size={32} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={props.url}
          title={props.shareText}
          windowWidth={750}
          windowHeight={600}
          className={styles.button}>
          <LinkedinIcon size={32} />
        </LinkedinShareButton>
        <RedditShareButton
          url={props.url}
          title={props.shareText}
          windowWidth={660}
          windowHeight={460}
          className={styles.button}>
          <RedditIcon
            size={32} />
        </RedditShareButton>
        <EmailShareButton
          url={props.url}
          subject={props.shareText}
          body="body"
          className={styles.button}>
          <EmailIcon
            size={32} />
        </EmailShareButton>
      </Flex>
    </Box>
  )
}

export default Share